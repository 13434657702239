.page_title {
  margin-top: 5px;
  /* background-color: cadetblue; */

  color: white;
  border-color: #454d55 !important;
  border-width: 3px !important;

}

.table-dark {
  /* background-color: cadetblue !important; */
  background-color: #4592af !important;
  /* background-color: #5c5757 !important; */
  color: rgba(255, 255, 255, 0.87)!important;
  /* background-color: #4b89ac!important; */
}

.dashboard-container {
  margin-top: 15px ;
  margin-right: 20px;
  padding: 20px 20px 20px 20px;
}