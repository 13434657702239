.ticket_main_container {
  margin-top: 3rem;
  display: grid;
  grid-template-columns: 1fr 8fr 2fr 1fr;
  grid-template-rows: 100px 5fr;
  grid-template-areas:
    '. filter side .'
    '. ticketList ticketList .';
  min-height: 65vh;
}

.ticket_side_container {
  grid-area: side;
  text-align: right;
}

.ticket_list_container {
  grid-area: ticketList;
}

.ticket_top_side_left_container {
  grid-area: filter;
}

.ticket_list_title {
  grid-area: title;
  color: orange;
  font-weight: bold;
  font-size: 2rem;
  text-align: center;
}

.create_ticket_card {
  margin-top: 3rem;
  background-color: rgb(180, 178, 178);
}

.ck.ck-editor__main > .ck-editor__editable {
  min-height: 300px;
}

.note_body {
  padding: 20px;
}

@media only screen and (max-width: 1100px) {
  .ticket_main_container {
    margin-top: 3rem;
    display: grid;
    grid-template-columns: 10px 7fr 2fr 10px;
    grid-template-rows: 200px 5fr;
    grid-template-areas:
      'filter filter side side'
      '. ticketList ticketList .';
    min-height: 65vh;
  }
}
