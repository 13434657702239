.dog_top_section img {
  max-width: 80%;
}

/* .dog_lower_section {
  min-height: 50vh
}

.notes_section {
  min-height: 50vh;
} */

.dog_notes,
.files_container {
  min-height: 50vh;
}

.dog_notes_parent {
  position: relative;
}

.assign_reminder_module {
  position: absolute;
  left: 50%;
  top: 20%;
  transform: translateX(-60%);
  border: 5px#6c757d solid;
  border-radius: 15px;
  padding: 50px 100px;
  z-index: 101;
  background-color: white;
  text-align: center;
}

.dog_view_container {
  position: relative;
}

.dog_background_blur {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vw;
  background: rgba(0, 0, 0, 0.5);
}
