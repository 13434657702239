.sideNavBar {
  margin-top: 40px;
  max-width: 10%;
}

.sideNavBarGroup {
  position: -webkit-sticky;
  position: sticky;
  top: 40px;
}

.profile-cards {
  max-width: 90%;
  margin-left: 20px;
}

.sideNavBar-icon {
  margin-top: 50px;
}

.sideIcon {
  color: #bbbfca;
}

.activities {
  margin-left: 20px;
  flex: 0 0 92%;
  max-width: 92%;
}
