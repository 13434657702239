.file-input {
  display: none;
}

.file-upload-btn {
  color: white;
  text-transform: uppercase;
  outline: none;
  background-color: #4aa1f3;
  font-weight: bold;
  padding: 8px 15px;
  margin-bottom: 5px;
}

.container-dropfile {
  transform: translateY(-100%);
}

.container-dropfile p {
  color: red;
  text-align: center;
}

.drop-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 90%;
  height: 200px;
  border: 4px dashed #4aa1f3;
}

.upload-icon {
  width: 50px;
  height: 50px;
  background: url(../images/upload.png) no-repeat center center;
  background-size: 100%;
  text-align: center;
  margin: 0 auto;
  padding-top: 30px;
}

.drop-message {
  text-align: center;
  color: #4aa1f3;
  font-family: Arial;
  font-size: 20px;
}

.file-display-container {
  width: 805px;
}

.file-status-bar {
  width: 100%;
  vertical-align: top;
  position: relative;

}

.file-status-bar > div {
  overflow: hidden;
}

.file-type {
  display: inline-block !important;
  font-size: 12px;
  font-weight: 700;
  line-height: 13px;
  padding: 0 4px;
  border-radius: 2px;
  box-shadow: 1px 1px 2px #abc;
  color: #fff;
  background: #0080c8;
  text-transform: uppercase;
}

.file-name {
  display: inline-block;
  vertical-align: top;
  margin-left: 50px;
  color: #4aa1f3;
}

.file-type-logo {
  width: 50px;
  height: 50px;
  background: url(../images/generic.png) no-repeat center center;
  background-size: 100%;
}

.file-size {
  display: inline-block;
  vertical-align: top;
  color: #30693d;
  margin-left: 10px;
  margin-right: 5px;
  margin-left: 10px;
  color: #444242;
  font-weight: 700;
  font-size: 14px;
}

.file-remove {
  top: 20px;
  right: 10px;
  line-height: 15px;
  cursor: pointer;
  color: red;
  margin-right: -10px;
}
.file-error-message {
  color: red;
  font-weight: bolder;
}

.file-progressBar {
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  display: inline-block;
  margin: 0px 10px 5px 5px;
  vertical-align: top;
  flex: 6 !important;
  margin-left: 50px;
  box-sizing: content-box;
  width: calc(100% - 50px);
}

.file-progressBar div {
  height: auto;
  color: #fff;
  text-align: right;
  line-height: 15px;
  width: 0;
  background-color: #4caf50;
  border-radius: 3px;
  font-size: 13px;
}
