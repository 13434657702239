@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700;900&display=swap);
/* .nav-link {
  color: orange !important;
} */

.navbar-dark .nav-item.active .nav-link,
.navbar-dark .nav-item .nav-link:active,
.navbar-dark .nav-item .nav-link:focus,
.navbar-dark .nav-item:hover .nav-link {
  color: orange !important;
}

/* .navbar-dark .navbar-nav .active > .nav-link, */
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show > .nav-link {
  color: black !important;
  font-weight: bold;
}

.header_logo {
  width: 150px;
  height: auto;
}

.page_title {
  margin-top: 5px;
  /* background-color: cadetblue; */

  color: white;
  border-color: #454d55 !important;
  border-width: 3px !important;

}

.table-dark {
  /* background-color: cadetblue !important; */
  background-color: #4592af !important;
  /* background-color: #5c5757 !important; */
  color: rgba(255, 255, 255, 0.87)!important;
  /* background-color: #4b89ac!important; */
}

.dashboard-container {
  margin-top: 15px ;
  margin-right: 20px;
  padding: 20px 20px 20px 20px;
}
.dog_top_section img {
  max-width: 80%;
}

/* .dog_lower_section {
  min-height: 50vh
}

.notes_section {
  min-height: 50vh;
} */

.dog_notes,
.files_container {
  min-height: 50vh;
}

.dog_notes_parent {
  position: relative;
}

.assign_reminder_module {
  position: absolute;
  left: 50%;
  top: 20%;
  -webkit-transform: translateX(-60%);
          transform: translateX(-60%);
  border: 5px#6c757d solid;
  border-radius: 15px;
  padding: 50px 100px;
  z-index: 101;
  background-color: white;
  text-align: center;
}

.dog_view_container {
  position: relative;
}

.dog_background_blur {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vw;
  background: rgba(0, 0, 0, 0.5);
}

.file-input {
  display: none;
}

.file-upload-btn {
  color: white;
  text-transform: uppercase;
  outline: none;
  background-color: #4aa1f3;
  font-weight: bold;
  padding: 8px 15px;
  margin-bottom: 5px;
}

.container-dropfile {
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
}

.container-dropfile p {
  color: red;
  text-align: center;
}

.drop-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 90%;
  height: 200px;
  border: 4px dashed #4aa1f3;
}

.upload-icon {
  width: 50px;
  height: 50px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAABmJLR0QA/wD/AP+gvaeTAAACCklEQVR4nO3cMWpUURxG8TOJoCAEBDvBysLGxt4mZZqsIK5AdAnJEnQJZgU2ljb2NjYRTBOwCwiCoIUmKWKa4Ggy8/73u2/e+cGDNGHevaf7QmZG/06X/P3ZIG9RZC39AlNngDADhBkgzABhBggzQJgBwgwQZoAwA4QZIMwAYQYIM0CYAcIMEGaAMAOEGSDMAGEGCDNAmAHCDBBmgDADhBkgzABhBggzQJgBwgwQZoAwA4QZIMwAYQYIM0BYywD7nP/P73WfZS3yma8H+Nzu3AYOWOxCWj6fgY2iO4h7BPwgf8nznp/A47LTd+IF+Yue9zwvPHc3ZsAb8pd9+XlL598rMaQ7wBH5S794vgB3S0/coSfAL/KX/xvYLD5rt/bIB9itPmTP1oB35C7/PbBefsrO3QOOaX/5X4H7Dc43ClvACe0u/wTYbnKyEXlFuwAvG51pVG4CH6i//I/ArUZnGp0HwDfqLv878LDZaUbqKXUBdhqeY9QWna7/9azkxFxl6Ol6pSfmKkNN15OYmKsMMV1PYmKusux0PamJucqi0/UkJ+Yq152uJz0xV9nj6gF2M6+42q46XTsxF/rfdO3E3MC86dqJuaG/TddOzA1dnq6dmAMuputRT8w30i+whEPg2Z+fPyVfRJIkSZIkSZIkSdI8M87/qK0QvzErzABhBggzQJgBwgwQZoCwMzQLhWMQB9y+AAAAAElFTkSuQmCC) no-repeat center center;
  background-size: 100%;
  text-align: center;
  margin: 0 auto;
  padding-top: 30px;
}

.drop-message {
  text-align: center;
  color: #4aa1f3;
  font-family: Arial;
  font-size: 20px;
}

.file-display-container {
  width: 805px;
}

.file-status-bar {
  width: 100%;
  vertical-align: top;
  position: relative;
}

.file-status-bar > div {
  overflow: hidden;
}

.file-type {
  display: inline-block !important;
  font-size: 12px;
  font-weight: 700;
  line-height: 13px;
  padding: 0 4px;
  border-radius: 2px;
  box-shadow: 1px 1px 2px #abc;
  color: #fff;
  background: #0080c8;
  text-transform: uppercase;
}

.file-name {
  display: inline-block;
  vertical-align: top;
  margin-left: 50px;
  color: #4aa1f3;
}

.file-type-logo {
  width: 50px;
  height: 50px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAAY1BMVEUAAACVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaukxN9AAAAIHRSTlMACRIVFhsdHjA1Njc4OTpRVnB1qquttbrHytr19/n7/XWuHHsAAADMSURBVFjD7dfbDsIgDAZg1AmeRQcemeP9n9IQINsiNaVe2v+Shi8BQgNChEjj/Pd0t9NSgFEelcscAiwO8K8VADiPzboMhJLVcEL9GYUNCGh4i2K9ecACChCzKGzJQBZ2ZAAWsAAooIEs7MlAFg5koCzUAMXTrAKS0C/IQBKudCAJkg6IJoyc64DpbQ13844Hyv2iwwOm3F3wgPwVALp2BfARzQADDDDAAAMMMPAXQHusSTsF8B/PcdwAWBJgB0CRgNFrXShbuwpn0vw3rTqGTJqHWlwAAAAASUVORK5CYII=) no-repeat center center;
  background-size: 100%;
}

.file-size {
  display: inline-block;
  vertical-align: top;
  color: #30693d;
  margin-left: 10px;
  margin-right: 5px;
  margin-left: 10px;
  color: #444242;
  font-weight: 700;
  font-size: 14px;
}

.file-remove {
  top: 20px;
  right: 10px;
  line-height: 15px;
  cursor: pointer;
  color: red;
  margin-right: -10px;
}
.file-error-message {
  color: red;
  font-weight: bolder;
}

.file-progressBar {
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  display: inline-block;
  margin: 0px 10px 5px 5px;
  vertical-align: top;
  flex: 6 1 !important;
  margin-left: 50px;
  box-sizing: content-box;
  width: calc(100% - 50px);
}

.file-progressBar div {
  height: auto;
  color: #fff;
  text-align: right;
  line-height: 15px;
  width: 0;
  background-color: #4caf50;
  border-radius: 3px;
  font-size: 13px;
}

.ticket_main_container {
  margin-top: 3rem;
  display: grid;
  grid-template-columns: 1fr 8fr 2fr 1fr;
  grid-template-rows: 100px 5fr;
  grid-template-areas:
    '. filter side .'
    '. ticketList ticketList .';
  min-height: 65vh;
}

.ticket_side_container {
  grid-area: side;
  text-align: right;
}

.ticket_list_container {
  grid-area: ticketList;
}

.ticket_top_side_left_container {
  grid-area: filter;
}

.ticket_list_title {
  grid-area: title;
  color: orange;
  font-weight: bold;
  font-size: 2rem;
  text-align: center;
}

.create_ticket_card {
  margin-top: 3rem;
  background-color: rgb(180, 178, 178);
}

.ck.ck-editor__main > .ck-editor__editable {
  min-height: 300px;
}

.note_body {
  padding: 20px;
}

@media only screen and (max-width: 1100px) {
  .ticket_main_container {
    margin-top: 3rem;
    display: grid;
    grid-template-columns: 10px 7fr 2fr 10px;
    grid-template-rows: 200px 5fr;
    grid-template-areas:
      'filter filter side side'
      '. ticketList ticketList .';
    min-height: 65vh;
  }
}

.file-input {
  display: none;
}

.file-upload-btn {
  color: white;
  text-transform: uppercase;
  outline: none;
  background-color: #4aa1f3;
  font-weight: bold;
  padding: 8px 15px;
  margin-bottom: 5px;
}

.container-dropfile {
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
}

.container-dropfile p {
  color: red;
  text-align: center;
}

.drop-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 90%;
  height: 200px;
  border: 4px dashed #4aa1f3;
}

.upload-icon {
  width: 50px;
  height: 50px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAABmJLR0QA/wD/AP+gvaeTAAACCklEQVR4nO3cMWpUURxG8TOJoCAEBDvBysLGxt4mZZqsIK5AdAnJEnQJZgU2ljb2NjYRTBOwCwiCoIUmKWKa4Ggy8/73u2/e+cGDNGHevaf7QmZG/06X/P3ZIG9RZC39AlNngDADhBkgzABhBggzQJgBwgwQZoAwA4QZIMwAYQYIM0CYAcIMEGaAMAOEGSDMAGEGCDNAmAHCDBBmgDADhBkgzABhBggzQJgBwgwQZoAwA4QZIMwAYQYIM0BYywD7nP/P73WfZS3yma8H+Nzu3AYOWOxCWj6fgY2iO4h7BPwgf8nznp/A47LTd+IF+Yue9zwvPHc3ZsAb8pd9+XlL598rMaQ7wBH5S794vgB3S0/coSfAL/KX/xvYLD5rt/bIB9itPmTP1oB35C7/PbBefsrO3QOOaX/5X4H7Dc43ClvACe0u/wTYbnKyEXlFuwAvG51pVG4CH6i//I/ArUZnGp0HwDfqLv878LDZaUbqKXUBdhqeY9QWna7/9azkxFxl6Ol6pSfmKkNN15OYmKsMMV1PYmKusux0PamJucqi0/UkJ+Yq152uJz0xV9nj6gF2M6+42q46XTsxF/rfdO3E3MC86dqJuaG/TddOzA1dnq6dmAMuputRT8w30i+whEPg2Z+fPyVfRJIkSZIkSZIkSdI8M87/qK0QvzErzABhBggzQJgBwgwQZoCwMzQLhWMQB9y+AAAAAElFTkSuQmCC) no-repeat center center;
  background-size: 100%;
  text-align: center;
  margin: 0 auto;
  padding-top: 30px;
}

.drop-message {
  text-align: center;
  color: #4aa1f3;
  font-family: Arial;
  font-size: 20px;
}

.file-display-container {
  width: 805px;
}

.file-status-bar {
  width: 100%;
  vertical-align: top;
  position: relative;
}

.file-status-bar > div {
  overflow: hidden;
}

.file-type {
  display: inline-block !important;
  font-size: 12px;
  font-weight: 700;
  line-height: 13px;
  padding: 0 4px;
  border-radius: 2px;
  box-shadow: 1px 1px 2px #abc;
  color: #fff;
  background: #0080c8;
  text-transform: uppercase;
}

.file-name {
  display: inline-block;
  vertical-align: top;
  margin-left: 50px;
  color: #4aa1f3;
}

.file-type-logo {
  width: 50px;
  height: 50px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAAY1BMVEUAAACVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaukxN9AAAAIHRSTlMACRIVFhsdHjA1Njc4OTpRVnB1qquttbrHytr19/n7/XWuHHsAAADMSURBVFjD7dfbDsIgDAZg1AmeRQcemeP9n9IQINsiNaVe2v+Shi8BQgNChEjj/Pd0t9NSgFEelcscAiwO8K8VADiPzboMhJLVcEL9GYUNCGh4i2K9ecACChCzKGzJQBZ2ZAAWsAAooIEs7MlAFg5koCzUAMXTrAKS0C/IQBKudCAJkg6IJoyc64DpbQ13844Hyv2iwwOm3F3wgPwVALp2BfARzQADDDDAAAMMMPAXQHusSTsF8B/PcdwAWBJgB0CRgNFrXShbuwpn0vw3rTqGTJqHWlwAAAAASUVORK5CYII=) no-repeat center center;
  background-size: 100%;
}

.file-size {
  display: inline-block;
  vertical-align: top;
  color: #30693d;
  margin-left: 10px;
  margin-right: 5px;
  margin-left: 10px;
  color: #444242;
  font-weight: 700;
  font-size: 14px;
}

.file-remove {
  top: 20px;
  right: 10px;
  line-height: 15px;
  cursor: pointer;
  color: red;
  margin-right: -10px;
}
.file-error-message {
  color: red;
  font-weight: bolder;
}

.file-progressBar {
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  display: inline-block;
  margin: 0px 10px 5px 5px;
  vertical-align: top;
  flex: 6 1 !important;
  margin-left: 50px;
  box-sizing: content-box;
  width: calc(100% - 50px);
}

.file-progressBar div {
  height: auto;
  color: #fff;
  text-align: right;
  line-height: 15px;
  width: 0;
  background-color: #4caf50;
  border-radius: 3px;
  font-size: 13px;
}

.file-input {
  display: none;
}

.file-upload-btn {
  color: white;
  text-transform: uppercase;
  outline: none;
  background-color: #4aa1f3;
  font-weight: bold;
  padding: 8px 15px;
  margin-bottom: 5px;
}

.container-dropfile {
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
}

.container-dropfile p {
  color: red;
  text-align: center;
}

.drop-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 90%;
  height: 200px;
  border: 4px dashed #4aa1f3;
}

.upload-icon {
  width: 50px;
  height: 50px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAABmJLR0QA/wD/AP+gvaeTAAACCklEQVR4nO3cMWpUURxG8TOJoCAEBDvBysLGxt4mZZqsIK5AdAnJEnQJZgU2ljb2NjYRTBOwCwiCoIUmKWKa4Ggy8/73u2/e+cGDNGHevaf7QmZG/06X/P3ZIG9RZC39AlNngDADhBkgzABhBggzQJgBwgwQZoAwA4QZIMwAYQYIM0CYAcIMEGaAMAOEGSDMAGEGCDNAmAHCDBBmgDADhBkgzABhBggzQJgBwgwQZoAwA4QZIMwAYQYIM0BYywD7nP/P73WfZS3yma8H+Nzu3AYOWOxCWj6fgY2iO4h7BPwgf8nznp/A47LTd+IF+Yue9zwvPHc3ZsAb8pd9+XlL598rMaQ7wBH5S794vgB3S0/coSfAL/KX/xvYLD5rt/bIB9itPmTP1oB35C7/PbBefsrO3QOOaX/5X4H7Dc43ClvACe0u/wTYbnKyEXlFuwAvG51pVG4CH6i//I/ArUZnGp0HwDfqLv878LDZaUbqKXUBdhqeY9QWna7/9azkxFxl6Ol6pSfmKkNN15OYmKsMMV1PYmKusux0PamJucqi0/UkJ+Yq152uJz0xV9nj6gF2M6+42q46XTsxF/rfdO3E3MC86dqJuaG/TddOzA1dnq6dmAMuputRT8w30i+whEPg2Z+fPyVfRJIkSZIkSZIkSdI8M87/qK0QvzErzABhBggzQJgBwgwQZoCwMzQLhWMQB9y+AAAAAElFTkSuQmCC) no-repeat center center;
  background-size: 100%;
  text-align: center;
  margin: 0 auto;
  padding-top: 30px;
}

.drop-message {
  text-align: center;
  color: #4aa1f3;
  font-family: Arial;
  font-size: 20px;
}

.file-display-container {
  width: 805px;
}

.file-status-bar {
  width: 100%;
  vertical-align: top;
  position: relative;

}

.file-status-bar > div {
  overflow: hidden;
}

.file-type {
  display: inline-block !important;
  font-size: 12px;
  font-weight: 700;
  line-height: 13px;
  padding: 0 4px;
  border-radius: 2px;
  box-shadow: 1px 1px 2px #abc;
  color: #fff;
  background: #0080c8;
  text-transform: uppercase;
}

.file-name {
  display: inline-block;
  vertical-align: top;
  margin-left: 50px;
  color: #4aa1f3;
}

.file-type-logo {
  width: 50px;
  height: 50px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAAY1BMVEUAAACVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaVpaaukxN9AAAAIHRSTlMACRIVFhsdHjA1Njc4OTpRVnB1qquttbrHytr19/n7/XWuHHsAAADMSURBVFjD7dfbDsIgDAZg1AmeRQcemeP9n9IQINsiNaVe2v+Shi8BQgNChEjj/Pd0t9NSgFEelcscAiwO8K8VADiPzboMhJLVcEL9GYUNCGh4i2K9ecACChCzKGzJQBZ2ZAAWsAAooIEs7MlAFg5koCzUAMXTrAKS0C/IQBKudCAJkg6IJoyc64DpbQ13844Hyv2iwwOm3F3wgPwVALp2BfARzQADDDDAAAMMMPAXQHusSTsF8B/PcdwAWBJgB0CRgNFrXShbuwpn0vw3rTqGTJqHWlwAAAAASUVORK5CYII=) no-repeat center center;
  background-size: 100%;
}

.file-size {
  display: inline-block;
  vertical-align: top;
  color: #30693d;
  margin-left: 10px;
  margin-right: 5px;
  margin-left: 10px;
  color: #444242;
  font-weight: 700;
  font-size: 14px;
}

.file-remove {
  top: 20px;
  right: 10px;
  line-height: 15px;
  cursor: pointer;
  color: red;
  margin-right: -10px;
}
.file-error-message {
  color: red;
  font-weight: bolder;
}

.file-progressBar {
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  display: inline-block;
  margin: 0px 10px 5px 5px;
  vertical-align: top;
  flex: 6 1 !important;
  margin-left: 50px;
  box-sizing: content-box;
  width: calc(100% - 50px);
}

.file-progressBar div {
  height: auto;
  color: #fff;
  text-align: right;
  line-height: 15px;
  width: 0;
  background-color: #4caf50;
  border-radius: 3px;
  font-size: 13px;
}

.hoverButton {
  position: -webkit-sticky;
  position: sticky;
  float: right;
  bottom: 0;
  margin-right: 10px;
  margin-top: 10px;
}

.upArrow {
  /* color: #3d6cb9; */
  color: hsl(39, 100%, 49%);
}

.sideNavBar {
  margin-top: 40px;
  max-width: 10%;
}

.sideNavBarGroup {
  position: -webkit-sticky;
  position: sticky;
  top: 40px;
}

.profile-cards {
  max-width: 90%;
  margin-left: 20px;
}

.sideNavBar-icon {
  margin-top: 50px;
}

.sideIcon {
  color: #bbbfca;
}

.activities {
  margin-left: 20px;
  flex: 0 0 92%;
  max-width: 92%;
}

body {
  font-family: 'Source Sans Pro', sans-serif;
}

.table-dark {
  background-color: cadetblue;
}

.bg-blue {
  background-color: #3d6cb9;
}

.noborder {
  border-style: hidden;
  position: relative;
  width: 87%;
  padding-left: 5px;
}

#backDrop {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
}

/* PROFILE PAGE */
.section_divider {
  margin-bottom: 1rem;
  padding: 1rem;
  min-height: 200px;
}

.pointer {
  cursor: pointer;
}

.footer {
  min-height: 150px;
  background-color: #3d6cb9;
  margin-top: 20px;
  border-top: 5px solid black;
}

.wrapper {
  background-color: white;
  width: 400px;
  padding: 2rem;
  box-shadow: 0 1.5rem 1rem -1rem rgba(0, 0, 0, 0.1);
  border-radius: 0.3rem;
}

.email-chip {
  background-color: #d4d5d6;
  display: inline-block;
  font-size: 14px;
  border-radius: 30px;
  height: 30px;
  padding: 0 4px 0rem 1rem;
  display: inline-flex;
  align-items: center;
  margin: 0.3rem 0.3rem 0.3rem 0.2rem;
}

.chip-button {
  background-color: white;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  font: inherit;
  margin-left: 10px;
  font-weight: bold;
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tagline {
  padding-top: 40px;
}

.autoComplete {
  position: absolute;
  border-width: 0px 1px 1px 1px;
  /* border-style: solid; */
  /* border-color: goldenrod; */
  background-color: whitesmoke;
  /* right: 0px; */
  width: 585px;
  z-index: 9;
}

.option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 580px;
  padding: 5px;
}
.option:hover {
  background-color: grey;
  font-weight: bold;
  color: white;
}

/* BLOG MODULE */
.blog_card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 2px solid rgb(241, 16, 16);
  border-radius: 0.5rem;
}

.blogSummary {
  text-overflow: ellipsis;
  height: 125px;
  overflow: hidden;
}
.blogPost {
  text-overflow: ellipsis;
}

/* ADOPTION PAGE */
.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  grid-template-rows: 2fr 2fr 3fr;
  grid-template-areas:
    /* 'thank-you thank-you adopt-picture' */
    'adoption-process adoption-process adopt-picture'
    'adoption-process adoption-process adopt-picture'
    'apply-online downloadable-apps adopt-picture';

  height: 110vh;
  margin: 2em;
}

.thank-you {
  grid-area: thank-you;
  color: crimson;
  margin-bottom: 2rem;
}

.thank-you h1 {
  padding-bottom: 0;
  margin-bottom: 0;
}

.thank-you h5 {
  padding-top: 0;
  font-weight: bolder;
}

.adoption-process {
  grid-area: adoption-process;
  padding: 1rem;
  margin-right: 1rem;
  background-color: #fdd365;
  border-radius: 2rem;
  margin-bottom: 2 rem;
}

.bold {
  font-weight: bold;
}

.roman-numerals {
  list-style-type: lower-roman;
}

.apply-online {
  margin-top: 1em;
  grid-area: apply-online;
}

.downloadable-apps {
  margin-top: 1em;
  grid-area: downloadable-apps;
  text-decoration: none !important;
}

/* Background for Admin NAVBAR and Admin them */
.bg-theme {
  background-color: #3d6cb9;
}

.bg-theme-orange {
  background-color: orange;
}
.bg-container {
  /* background-color: #212529; */
  background-color: #f5f5f5;
}
.bg-foster-theme {
  background-color: #3d6cb9;
  color: #f5f5f5;
}
.btn-background {
  background-color: #3d6cb9 !important;
  border-color: #3d6cb9 !important;
  color: #f5f5f5 !important;
}

.note-text {
  overflow-wrap: break-word !important;
  word-wrap: break-word !important;
  word-break: break-all;
}
.note-text,
p,
a {
  overflow-wrap: break-word !important;
  word-wrap: break-word !important;
}
.card-body {
  max-width: 100%;
}

.ck-editor__editable_inline,
.ck-editor,
.ck-content {
  min-height: 100px;
  width: 100% !important;
  overflow-wrap: break-word;
}

.email-editor,
.CkEditor {
  width: 100%;
}

/* .ck.ck-reset,
.ck.ck-reset_all,
.ck.ck-reset_all * {
  width: 100% !important;
} */

.gt-modal {
  max-width: 80% !important;
}

.button-link:hover {
  text-decoration: none !important;
}

.carousel-control-next {
  z-index: 44;
}

.btn-orange {
  background-color: orange;
}
.btn-orange:hover {
  background-color: rgb(194, 149, 66);
}

.pet-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.card img {
  display: block;
  height: 200px;
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  font-family: 'object-fit:cover';
  object-fit: cover;
}
.border-thick {
  border-width: thick;
}

.nav-link {
  font-size: 1.2em;
}

.item img {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 300px;
}

/*CKEditor Background Colors */
.marker-yellow {
  background-color: yellow !important;
}
.marker-pink {
  background-color: pink !important;
}
.marker-green {
  background-color: greenyellow !important;
}
.marker-blue {
  background-color: blue !important;
  color: white;
}
.pen-red {
  background-color: red !important;
  color: white !important;
}
.pen-green {
  background-color: green !important;
  color: white;
}

/* Extra Large */
@media screen and (max-width: 2000px) {
  .slide1 {
    /* position: absolute; */
    top: 50%;
    -webkit-transform: translateX(30%) translateY(-40%);
            transform: translateX(30%) translateY(-40%);
  }
  .slide1-display-2 {
    font-size: 5.5rem;
    line-height: 1;
  }
  .carousel-item {
    height: 700px;
  }
  .slide2 {
    /* position: absolute; */
    top: 50%;
    z-index: 1;
    -webkit-transform: translateX(-40%) translateY(-50%);
            transform: translateX(-40%) translateY(-50%);
  }
  .slide2-display-2 {
    font-size: 5rem;
    line-height: 1;
  }
  .slide2-subtitle {
    font-size: 1.75rem;
    color: white !important;
  }

  .carousel-item2 {
    height: 700px;
  }
  .slide3 {
    /* position: absolute; */
    top: 50%;
    -webkit-transform: translateX(-0%) translateY(-50%);
            transform: translateX(-0%) translateY(-50%);
  }
  .slide3-display-2 {
    font-size: 5rem;
    line-height: 1;
  }
  .slide3-subtitle {
    font-size: 1.75rem;
  }

  .carousel-item3 {
    height: 700px;
  }
}
@media screen and (max-width: 1600px) {
  .slide1 {
    /* position: absolute; */
    top: 50%;
    -webkit-transform: translateX(30%) translateY(-40%);
            transform: translateX(30%) translateY(-40%);
  }
  .slide1-display-2 {
    font-size: 5.5rem;
    line-height: 1;
  }
  .carousel-item {
    height: 550px;
  }
  .slide2 {
    /* position: absolute; */
    top: 50%;
    -webkit-transform: translateX(-40%) translateY(-55%);
            transform: translateX(-40%) translateY(-55%);
  }
  .slide2-display-2 {
    font-size: 3rem;
    line-height: 1;
    z-index: 1;
  }
  .slide2-subtitle {
    color: white !important;
    z-index: 1;
  }

  .carousel-item2 {
    height: 550px;
  }
  .slide3 {
    /* position: absolute; */
    top: 50%;
    -webkit-transform: translateX(-0%) translateY(-50%);
            transform: translateX(-0%) translateY(-50%);
  }
  .slide3-display-2 {
    font-size: 5rem;
    line-height: 1;
  }
  .slide3-subtitle {
    font-size: 1.75rem;
  }

  .carousel-item3 {
    height: 550px;
  }
}
@media screen and (max-width: 1200px) {
  .slide1 {
    /* position: absolute; */
    top: 50%;
    -webkit-transform: translateX(30%) translateY(-50%);
            transform: translateX(30%) translateY(-50%);
  }
  .slide1-display-2 {
    font-size: 4rem;
    line-height: 1;
  }
  .sub-title-1 {
    font-size: 2rem;
  }
  .carousel-item {
    height: 550px;
  }
  .slide2 {
    /* position: absolute; */
    top: 50%;
    -webkit-transform: translateX(-40%) translateY(-55%);
            transform: translateX(-40%) translateY(-55%);
  }
  .slide2-display-2 {
    font-size: 2.5rem;
    line-height: 1;
    color: white !important;
  }
  .slide2-subtitle {
    color: white !important;
  }
  .carousel-item2 {
    height: 550px;
  }
  .slide3 {
    /* position: absolute; */
    top: 50%;
    -webkit-transform: translateX(0%) translateY(-50%);
            transform: translateX(0%) translateY(-50%);
  }
  .slide3-display-2 {
    font-size: 5rem;
    line-height: 1;
  }
  .slide3-subtitle {
    font-size: 1.85rem;
  }

  .carousel-item3 {
    height: 550px;
  }
}

@media screen and (max-width: 992px) {
  .slide1 {
    /* position: absolute; */
    top: 50%;
    -webkit-transform: translateX(40%) translateY(-50%);
            transform: translateX(40%) translateY(-50%);
  }
  .slide1-display-2 {
    font-size: 3rem;
    line-height: 1;
  }
  .sub-title-1 {
    font-size: 1.3rem;
  }
  .carousel-item {
    height: 400px;
  }
  .slide2 {
    /* position: absolute; */
    top: 50%;
    -webkit-transform: translateX(-0%) translateY(-55%);
            transform: translateX(-0%) translateY(-55%);
  }
  .slide2-display-2 {
    font-size: 2rem;
    line-height: 1;
    color: white !important;
  }
  .slide2-subtitle {
    color: white !important;
  }
  .carousel-item2 {
    height: 400px;
  }
  .slide3 {
    /* position: absolute; */
    top: 50%;
    -webkit-transform: translateX(0%) translateY(-50%);
            transform: translateX(0%) translateY(-50%);
  }
  .slide3-display-2 {
    font-size: 4rem;
    line-height: 1;
  }
  .slide3-subtitle {
    font-size: 1.25rem;
  }

  .carousel-item3 {
    height: 400px;
  }
  .contact-image {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .slide1 {
    /* position: absolute; */
    top: 50%;
    -webkit-transform: translateX(30%) translateY(-50%);
            transform: translateX(30%) translateY(-50%);
  }
  .slide1-display-2 {
    font-size: 2.5rem;
    line-height: 1;
  }
  .sub-title-1 {
    font-size: 1rem;
  }
  .carousel-item {
    height: 320px;
  }
  .slide2 {
    /* position: absolute; */
    top: 50%;
    -webkit-transform: translateX(0%) translateY(-50%);
            transform: translateX(0%) translateY(-50%);
  }
  .slide2-display-2 {
    font-size: 2rem;
    line-height: 1;
    color: white !important;
  }
  .slide2-subtitle {
    color: white !important;
  }
  .carousel-item2 {
    height: 320px;
  }
  .slide3 {
    /* position: absolute; */
    top: 50%;
    -webkit-transform: translateX(0%) translateY(-50%);
            transform: translateX(0%) translateY(-50%);
  }
  .slide3-display-2 {
    font-size: 2rem;
    line-height: 1;
  }
  .slide3-subtitle {
    font-size: 1rem;
  }

  .carousel-item3 {
    height: 320px;
  }
}

@media screen and (max-width: 576px) {
  .slide1 {
    /* position: absolute; */
    top: 50%;
    -webkit-transform: translateX(40%) translateY(-70%);
            transform: translateX(40%) translateY(-70%);
  }
  .slide1-display-2 {
    font-size: 2.5rem;
    line-height: 1;
  }
  .sub-title-1 {
    font-size: 1rem;
  }
  .carousel-item {
    height: 300px;
  }
  .slide2 {
    /* position: absolute; */
    top: 50%;
    -webkit-transform: translateX(0%) translateY(-50%);
            transform: translateX(0%) translateY(-50%);
  }
  .slide2-display-2 {
    font-size: 1.25rem;
    line-height: 1;
    color: white !important;
  }
  .slide2-subtitle {
    font-size: 0.75rem;
    color: white !important;
  }
  .carousel-item2 {
    height: 300px;
  }
  .slide3 {
    /* position: absolute; */
    top: 50%;
    -webkit-transform: translateX(0%) translateY(-50%);
            transform: translateX(0%) translateY(-50%);
  }
  .slide3-display-2 {
    font-size: 2rem;
    line-height: 1;
  }
  .slide3-subtitle {
    font-size: 1rem;
  }

  .carousel-item3 {
    height: 300px;
  }
}

@media screen and (max-width: 499px) {
  .slide1 {
    /* position: absolute; */
    top: 50%;
    -webkit-transform: translateX(30%) translateY(-80%);
            transform: translateX(30%) translateY(-80%);
  }
  .display-2 {
    font-size: 2rem;
    line-height: 1;
  }
  .sub-title-1 {
    font-size: 1rem;
  }
  .carousel-item {
    height: 200px;
  }
  .slide3 {
    /* position: absolute; */
    top: 50%;
    -webkit-transform: translateX(0%) translateY(-50%);
            transform: translateX(0%) translateY(-50%);
  }
  .slide3-display-2 {
    font-size: 1.5rem;
    line-height: 1;
  }
  .slide3-subtitle {
    font-size: 0.85rem;
  }

  .carousel-item3 {
    height: 300px;
  }
}

/* Padding */
.p {
  padding: 0.5rem;
}
.p-1 {
  padding: 1rem;
}
.p-2 {
  padding: 2rem;
}
.p-3 {
  padding: 3rem;
}
.py {
  padding: 0.5rem 0;
}
.py-1 {
  padding: 1rem 0;
}
.py-2 {
  padding: 2rem 0;
}
.py-3 {
  padding: 3rem 0;
}

/* Margin */
.m {
  margin: 0.5rem;
}
.m-1 {
  margin: 1rem;
}
.m-2 {
  margin: 2rem;
}
.m-3 {
  margin: 3rem;
}
.my {
  margin: 0.5rem 0;
}
.my-1 {
  margin: 1rem 0;
}
.my-2 {
  margin: 2rem 0;
}
.my-3 {
  margin: 3rem 0;
}

/* Grid */
.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
}

.grid-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
}

.grid-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
}

